import { slideProps } from "@/components/animations/utils";
import { motion, useInView } from "framer-motion";
import HeaderText from "./header-text";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import InvestorStars, { SmallStars } from "./investor-stars";
import { Button } from "@/components/ui/button";
import { Widget } from "@typeform/embed-react";
import tentacle2 from "@/assets/images/invest/6.webp"
import asteroids from "@/assets/images/invest/4.webp";
import asteroidleft from "@/assets/images/invest/5.webp";
export default function PitchDeck() {

    const ref = useRef(null);
    const iframeRef = useRef(null);
    const isInView = useInView(ref);
    const isInViewIframe = useInView(iframeRef);

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (isInView) setHasAnimated(true);
    }, [isInView])

    const scrollToTypeform = () => {
        const typeform = document.getElementById('typeform-container');
        if (typeform) {
            typeform.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div className='relative flex flex-col items-center justify-center lg:gap-y-[100px] mt-[70px] lg:mt-[179px]'>
            <img
                src={tentacle2}
                alt="tentacle"
                className="absolute top-[800px] left-0 h-0 lg:h-[880px]"
            />
            <img
                src={asteroidleft}
                alt="asteroids"
                className="absolute top-0 left-0 h-0 lg:h-[940px] z-[-1]"
            />
            <img
                src={asteroids}
                alt="asteroids"
                className="absolute -top-[400px] right-0 h-0 lg:h-[940px] z-[-1]"
            />
            <div className='flex flex-col items-center justify-center gap-y-[43px] lg:gap-y-[61px]'>
                <HeaderText
                    text={<>Unlocking Novel Play Data:
                        <br />
                        Our Full Publisher Strategy
                    </>}
                    tag='h2'
                    className='text-center text-investor-green text-[32px] leading-[36px] lg:text-5xl lg:leading-[52px] font-nekstbold font-bold text-balance'
                />
                <p className='text-center lg:text-left text-foreground text-base lg:text-lg leading-5 lg:leading-6 font-figtree font-normal max-w-[343px] lg:max-w-[779px]'>
                    Explore how our publishing approach captures and monetizes the untapped value of play data. Inside, you'll see:
                </p>
                <ul className='text-left text-foreground text-base lg:text-lg leading-5 lg:leading-5 font-figtree font-normal max-w-[294px] lg:max-w-[885px] mb-16 list-disc'>
                    <li><b>Data as a Digital Commodity:</b> How we identify and harness Novel Play Data</li>
                    <li><b>AI Acceleration:</b> Tools that reduce dev costs & time-to-market</li>
                    <li><b>Revenue Framework:</b> The breakdown of publishing fees, data licensing, and more</li>
                    <li><b>Go-to-Market & Roadmap:</b> Our steps to launch and scale decentralized AI-driven gaming</li>
                </ul>
            </div>
            <div ref={ref} className="flex flex-col w-full max-w-[848px] gap-y-8 mx-auto">
                <motion.div className='relative flex flex-col gap-y-[42px] col-span-1 w-full max-w-[883px] justify-center items-center' {...slideProps("up", hasAnimated)}>
                    <InvestorStars className="absolute -bottom-[36.3%] w-0 lg:w-full h-[180px] lg:h-[419px]" />
                    <SmallStars className="absolute -bottom-[50.3%] h-[180px] w-full lg:hidden" />
                    <div className='relative flex flex-col w-full px-4'>

                        <div className="relative aspect-[9_/_5.6] rounded-xl bg-[#070E22] w-full p-2 lg:p-4">

                            <motion.iframe
                                src="https://docs.google.com/presentation/d/e/2PACX-1vSCF9iMt8742ZI9hSNyVJZomJOy_imKMlmQZINeC8VXY5i9O24BuZx3dm3lyu1pAIIayt1Vn40BqxNy/embed?start=false&loop=false&delayms=3000&slide=id.g33f0fab19df_4_0"
                                frameBorder="0"
                                width="900"
                                height="900"
                                allowFullScreen={true}
                                ref={iframeRef}
                                className='w-full h-full rounded-xl bg-black'
                                {...slideProps("up", isInViewIframe)}
                            />
                        </div>

                    </div>
                </motion.div>
                <div className="relative flex flex-col text-center items-center mt-[120px] lg:mt-[223px] gap-y-[19px]">
                    <p className="max-w-[325px] lg:max-w-[505px] text-foreground text-xl leading-7 lg:text-[32px] lg:leading-[36px] font-figtree font-bold lg:font-nekstsemibold lg:font-semibold">
                        Want a downloadable version or have more questions?
                    </p>
                    <div>
                        <Button
                            variant="invest-cta"
                            className="!h-[58px]"
                            onClick={scrollToTypeform}
                        >
                            Contact Us
                        </Button>
                    </div>
                </div>
                <div id="typeform-container" className="flex flex-col gap-y-[59px] w-full mt-[80px] lg:mt-[240px] lg:mb-[450px] max-w-[776px] mx-auto">
                    <HeaderText
                        text="Connect with Us Directly"
                        tag="h2"
                        className="text-center text-investor-green text-[32px] leading-[36px] lg:text-5xl lg:leading-[52px] font-nekstbold font-bold"
                    />
                    <div className="relative aspect-[9_/_5.6] rounded-lg bg-black w-full h-full overflow-hidden border-2 border-[rgba(255,_255,_255,_0.20)]">

                        <Widget id="eKFecqCC" style={{ width: '100%', height: '100%' }} className="my-form" />
                    </div>
                </div>
                <p className="max-w-[720px] z-[1] mx-auto mb-10 text-center text-foreground text-[16px] leading-5 font-figtree font-normal">
                    <b>Disclaimer:</b> This page is for informational purposes and does not constitute a securities offering.
                    Please review our legal documentation for details on investment risks and conditions.
                </p>
            </div>
        </div>
    )
}