import logo from '@/assets/images/logo.png';
import greenRoom from '@/assets/images/invest/green-room.webp';
import bg from '@/assets/images/invest/bg.webp';
import investLizard from '@/assets/images/invest/invest-lizard.webp';

import Separator from '@/components/ui/separator';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useImagePositioning } from '@/hooks/use-image-positioning';

export default function InvestorHero() {
    const bgRef = useRef<HTMLImageElement>(null);
    const lizardRef = useRef<HTMLImageElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // Use our custom hook with desired parameters
    // 421px at 1440px width, lizard aspect ratio is 842/1220, position 23.5% from bottom
    const { handleResize, getDefaultStyles } = useImagePositioning({
        containerRef,
        bgRef,
        foregroundRef: lizardRef,
        targetWidth: 421,
        referenceWidth: 1440,
        bottomOffset: 0.22,
        aspectRatio: 842 / 1220
    });

    return (
        <>
            <section id="hero" className='relative flex flex-col items-center overflow-hidden'>
                <Link to='/' className='absolute top-[60px] lg:top-20 mx-auto flex flex-col gap-y-[23px] lg:flex-row items-center justify-center gap-x-6 hover:scale-[1.02] transition-all duration-300 z-10'>
                    <img src={logo} className='max-w-xs h-[26px] lg:h-[30px]' />
                    <Separator className='hidden lg:block' />
                    <img src={greenRoom} className='max-w-xs h-[25px] lg:h-[30px] green-room' />
                </Link>
                <div ref={containerRef} className='relative flex flex-col items-center w-[200%] lg:w-full z-0 mt-12 lg:mt-0 left-1/2 -translate-x-[25%] lg:left-auto lg:translate-x-0'>
                    <img
                        ref={bgRef}
                        src={bg}
                        className="w-full h-auto"
                        onLoad={handleResize}
                    />
                    <img
                        ref={lizardRef}
                        src={investLizard}
                        className='z-10'
                        onLoad={handleResize}
                        style={getDefaultStyles()}
                    />
                </div>
            </section>
        </>
    )
}