import HeaderText from "./header-text";

const questions = [
    {
        question: "What is the decentralized AI game publisher?",
        answer: "It's a new model that merges game publishing with AI-driven tools and Novel Play Data, enabling faster game development, lower costs, and valuable data assets."
    },
    {
        question: "How does it make money?",
        answer: "We capture revenue through publishing fees, AI tool licensing, and curated data pipelines. Our model shares the revenues with the studios while generating strong returns for backers."
    },
    {
        question: "What's the opportunity for investors?",
        answer: `You invest in a first-mover advantage in extracting untapped "play data" as a new digital commodity, unlocking AI's next wave in gaming. This positions you at the forefront of decentralized AI innovation with multiple revenue streams.`
    }
]

export default function TLDR() {
    return (
        <div className='relative flex flex-col items-center lg:items-start text-center lg:text-left justify-center mt-[60px] lg:my-[159px] max-w-[720px] mx-auto px-[27px]'>
            <HeaderText
                text='TL;DR — Quick Answers'
                tag='h2'
                className='text-investor-green text-[32px] leading-[36px] lg:text-5xl lg:leading-[52px] font-nekstbold font-bold text-balance'
            />
            <div className='flex flex-col gap-y-8 mt-16 max-w-[332px] lg:max-w-[667px]'>
                {questions.map((question, index) => (
                    <div key={`tldr-question-${index}`} className='flex flex-col gap-y-6 text-center lg:text-left'>
                        <p className='text-foreground text-[32px] leading-9 lg:text-4xl lg:leading-10 font-nekstbold font-bold'>
                            {question.question}
                        </p>
                        <p className='text-foreground text-[16px] lg:text-lg leading-5 font-figtree font-normal'>
                            {question.answer}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}