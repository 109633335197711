import { Button } from "@/components/ui/button";

export default function ContactUs() {

    const scrollToTypeform = () => {
        const typeform = document.getElementById('typeform-container');
        if (typeform) {
            typeform.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <div className='relative flex flex-col gap-y-7 lg:gap-y-10 items-center justify-center text-center mt-[120px] lg:mt-[130px]'>
            <p className='text-foreground text-xl leading-7 lg:text-2xl lg:leading-7 font-nekstbold font-bold'>
                Questions right away?
            </p>
            <Button variant='invest-cta' className='!h-10 lg:!h-[58px]' onClick={scrollToTypeform}>
                Contact Us
            </Button>
        </div>
    )
}