import ContactUs from '@/components/sections/investor/contact-us';
import InvestorHero from '@/components/sections/investor/hero';
import PitchDeck from '@/components/sections/investor/pitch-deck';
import PitchVideo from '@/components/sections/investor/pitch-video';
import PlayData from '@/components/sections/investor/play-data';
import TLDR from '@/components/sections/investor/tldr';
import { useConfig } from '@/hooks/use-config';
import { useSite } from '@/site-provider';
import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import logo from '@/assets/images/logo.png';
import greenRoom from '@/assets/images/invest/green-room.webp';

import { InvestorFooterContent } from '@/components/footer';
import bgFull from "@/assets/images/invest/bg-full.webp";
import lizard from "@/assets/images/invest/7.webp"
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import Separator from '@/components/ui/separator';

function InvestorPage() {

    ReactGA.send({
        hitType: 'pageview',
        page: "/invest",
        title: 'Investor'
    });

    const lizardRef = useRef(null);

    const isInView = useInView(lizardRef, { once: true });
    const [loadingInvest, setLoadingInvest] = useState(true);

    const [config, setConfig] = useConfig();
    const { setLoading } = useSite();

    useEffect(() => {
        setConfig({
            ...config,
            theme: "invest"
        })
        setLoading(false);
        setTimeout(() => {
            setLoadingInvest(false);
        }, 2000)
    }, [])

    return (
        <div className="relative w-full min-h-screen overflow-hidden">
            {loadingInvest && (
                <motion.div
                    className='fixed inset-0 z-[101] h-screen w-full bg-background flex justify-center items-center'
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <div className='bounce flex flex-col gap-y-[23px] lg:flex-row items-center justify-center gap-x-6 hover:scale-[1.02] transition-all duration-300 z-10'>
                        <img src={logo} className='max-w-xs h-[26px] lg:h-[30px]' />
                        <Separator className='hidden lg:block' />
                        <img src={greenRoom} className='max-w-xs h-[25px] lg:h-[30px] green-room' />
                    </div>
                </motion.div>
            )}
            <main className="min-h-screen min-w-full z-10 relative bg-cover bg-top" style={{ backgroundImage: `url(${bgFull})` }}>
                <div className='flex flex-col w-full'>
                    <InvestorHero />
                    <PlayData />
                    <PitchVideo />
                    <ContactUs />
                    <TLDR />
                    <PitchDeck />
                    {/* <Typeform />
                     */}
                </div>
                <motion.img
                    src={lizard}
                    alt="lizard"
                    className="absolute bottom-0 right-0 max-h-[780px] w-0 lg:w-auto -mb-[85px] z-0"
                    ref={lizardRef}
                    {...slideProps("right", isInView)}
                />
            </main>
            <footer
                key="footer"
                id="footer"
                className='w-full'
            >
                <InvestorFooterContent openAccordion={true} />
            </footer>
        </div>
    );
}

export default InvestorPage;