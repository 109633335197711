import { useEffect, useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import InvestorStars, { SmallStars } from './investor-stars';
import rocks from '@/assets/images/invest/3.webp';
import BlueLight from './blue-light';

export default function PitchVideo() {

    const ref = useRef(null);
    const isInView = useInView(ref);
    const iframeRef = useRef(null);
    const isInViewIframe = useInView(iframeRef);

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (isInView) setHasAnimated(true);
    }, [isInView])

    return (
        <section 
            id="pitch-video"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:justify-between md:flex-row gap-y-8 px-6 md:px-20 mt-[72px]"
        >
            {/* <BlueLight className='absolute top-0 -translate-y-1/3 -translate-x-[80%] h-0 lg:h-[1000px] lg:w-[500px] rounded-full rotate-[-22deg]' /> */}
            <img 
                src={rocks} 
                alt="asteroids" 
                className="absolute top-0 left-0 h-0 lg:h-[700px]" 
            />
            <div ref={ref} className='flex justify-center mx-auto w-full z-[1] h-full'>
                
                <motion.div className='relative flex flex-col gap-y-[42px] col-span-1 w-full max-w-[883px] justify-center items-center' {...slideProps("up", hasAnimated)}>
                    <div className='relative flex flex-col w-full'>
                        <InvestorStars className="absolute -bottom-[36.3%] w-0 lg:w-full h-[155px] lg:h-[419px]"/>
                        <SmallStars className="absolute -bottom-[50.3%] h-[155px] w-full lg:hidden"/>
                        <div className="relative aspect-[9_/_5.6] rounded-xl bg-[#070E22] w-full p-2 lg:p-4">
      
                            <iframe 
                                {...slideProps("up", isInViewIframe)}
                                width="900" 
                                height="718" 
                                className='w-full h-full rounded-xl bg-black'
                                src="https://www.youtube.com/embed/60-NvOsHF0w" 
                                title="Lizard Labs Publisher Pitch for Investors" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerPolicy="strict-origin-when-cross-origin"></iframe>
                        </div>
                        
                    </div>
                </motion.div>
            </div>
        </section>
    );
};