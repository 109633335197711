import { useEffect, useState, RefObject, CSSProperties } from 'react';

interface ImagePositioningProps {
  containerRef: RefObject<HTMLDivElement>;
  bgRef: RefObject<HTMLImageElement>;
  foregroundRef: RefObject<HTMLImageElement>;
  targetWidth: number;
  referenceWidth: number;
  bottomOffset: number;
  aspectRatio: number;
}

export function useImagePositioning({
  containerRef,
  bgRef,
  foregroundRef,
  targetWidth,
  referenceWidth,
  bottomOffset,
  aspectRatio,
}: ImagePositioningProps) {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleResize = () => {
    if (bgRef.current && foregroundRef.current && containerRef.current) {
      const bgHeight = bgRef.current.offsetHeight;
      const bgWidth = bgRef.current.offsetWidth;
      
      // Calculate scaling ratio based on reference width
      const scalingRatio = bgWidth / referenceWidth;
      const fgWidth = targetWidth * scalingRatio;
      
      // Calculate height based on the aspect ratio
      const fgHeight = fgWidth / aspectRatio;
      
      // Apply dimensions and positioning
      foregroundRef.current.style.width = `${fgWidth}px`;
      foregroundRef.current.style.height = `${fgHeight}px`;
      
      // Position relative to background
      const positionFromBottom = bgHeight * bottomOffset;  
      foregroundRef.current.style.bottom = `${positionFromBottom}px`;
      
      // Center horizontally
      foregroundRef.current.style.left = '50%';
      foregroundRef.current.style.transform = 'translateX(-50%)';
      
      // Ensure proper rendering
      foregroundRef.current.style.objectFit = 'contain';
      foregroundRef.current.style.position = 'absolute';
    }
  };

  // Handle image loading
  useEffect(() => {
    const bgImage = bgRef.current;
    const fgImage = foregroundRef.current;
    
    if (bgImage && fgImage) {
      const onImagesLoaded = () => {
        setImagesLoaded(true);
        handleResize();
      };
      
      // Check if images are already loaded
      if (bgImage.complete && fgImage.complete) {
        onImagesLoaded();
      } else {
        // Wait for both images to load
        let loadedCount = 0;
        const checkAllLoaded = () => {
          loadedCount++;
          if (loadedCount === 2) {
            onImagesLoaded();
          }
        };
        
        bgImage.addEventListener('load', checkAllLoaded);
        fgImage.addEventListener('load', checkAllLoaded);
        
        return () => {
          bgImage.removeEventListener('load', checkAllLoaded);
          fgImage.removeEventListener('load', checkAllLoaded);
        };
      }
    }
  }, [bgRef, foregroundRef]);

  // Set up window resize handler
  useEffect(() => {
    if (imagesLoaded) {
      handleResize();
      window.addEventListener('resize', handleResize);
      
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [imagesLoaded]);

  return {
    handleResize,
    imagesLoaded,
    getDefaultStyles: (): CSSProperties => ({
      position: 'absolute',
      bottom: `${bottomOffset * 100}%`,
      left: '50%',
      transform: 'translateX(-50%)',
      width: `${(targetWidth / referenceWidth) * 100}%`,
      objectFit: 'contain' as 'contain'
    })
  };
} 